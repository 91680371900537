import { Paragraph, TextRun } from "docx"

import { renderMonthName } from "../utils/utils.js"

export const renderLifeSection = lifeExperiences => {
  if (lifeExperiences.length === 0) {
    return []
  }

  return [
    new Paragraph({
      children: [
        new TextRun({
          text: "LIFE EXPERIENCE",
          bold: true,
          size: 21,
        }),
      ],
      spacing: {
        before: 200,
      },
      border: {
        bottom: {
          color: "#d3d3d3",
          space: 1,
          style: "single",
          size: 6,
        },
      },
    }),
    ...lifeExperiences
      .map(experience => {
        const {
          milestone = "TITLE",
          description = "",
          endDateMonth = "",
          endDateYear = "",
        } = experience
        return [
          new Paragraph({
            children: [
              new TextRun({
                text: `${milestone}`,
                bold: true,
                size: 21,
              }),
            ],
          }),
          new Paragraph({
            children: [
              new TextRun({
                text: `${renderMonthName(endDateMonth)} ${endDateYear}`,
                size: 20,
              }),
            ],
          }),
          description &&
            new Paragraph({
              bullet: {
                level: 0,
              },
              children: [
                new TextRun({
                  text: description,
                  font: "cambria",
                  size: 20,
                }),
              ],
            }),
        ]
      })
      .flat(),
  ]
}
