import {
  Paragraph,
  TextRun,
} from "docx"

import { renderMonthName } from "../utils/utils.js"

export const renderAwardSection = awardsExperiences => {
  if (awardsExperiences.length === 0) {
    return []
  }

  return [
    new Paragraph({
      children: [
        new TextRun({
          text: "HONORS AND AWARDS",
          bold: true,
          size: 21,
        }),
      ],
      spacing: {
        before: 200,
      },
      border: {
        bottom: {
          color: "#d3d3d3",
          space: 1,
          style: "single",
          size: 6,
        },
      },
    }),
    ...awardsExperiences
      .map(experience => {
        const {
          title = "AWARD",
          associatedOrg = "",
          issuer = "ISSUER",
          description = "",
          endDateMonth = "",
          endDateYear = "",
        } = experience
        return [
          new Paragraph({
            spacing: {
              before: 50,
            },
            children: [
              new TextRun({
                text: `${title}, `,
                bold: true,
                size: 21,
              }),
            ],
          }),
          new Paragraph({
            children: [
              new TextRun({
                text: `${issuer}${associatedOrg && `, associated with ${associatedOrg}` } | ${renderMonthName(endDateMonth)} ${endDateYear}`,
                size: 20,
              }),
            ],
          }),
          description ?
            new Paragraph({
              bullet: {
                level: 0,
              },
              children: [
                new TextRun({
                  text: description,
                  font: "cambria",
                  size: 20,
                }),
              ],
            }) : null,
        ].filter((element) => element !== null)
      })
      .flat(),
  ]
}
