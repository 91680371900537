import React, { useContext, useState, useEffect } from "react"
import PropTypes from "prop-types"
import AccountMenu from "../components/Firebase/account-menu"
import BannerMessage from "./BannerMessage"
import HeaderLogo from "../../static/assets/images/wingspans-w-icon_white.svg"
import { FirebaseContext } from "../components/Firebase/index"
import { useLocation } from "@reach/router"
import useWindowDimensions from "../components/hooks/useWindowDimensions"
import FinalistBanner from "./promotional/banners/finalists"
import "./header.css"

const Header = ({ siteTitle }) => {
  const location = useLocation()
  const { user } = useContext(FirebaseContext)

  const [isVisible, setIsVisible] = useState(true)
  const { height, width } = useWindowDimensions()

  useEffect(() => {
    // Hide the nav bar on the following pages. Include trailing slash and non-trailing slash versions.
    const hiddenPagePaths = [
      "/login",
      "/login/",
      "/login/individuals",
      "/login/individuals/",
      "/signup/individuals",
      "/signup/individuals/",
      "/es-ES/login",
      "/es-ES/login/",
      "/es-ES/login/individuals",
      "/es-ES/login/individuals/",
      "/es-ES/signup/individuals",
      "/es-ES/signup/individuals/",
      "/passwordless/authentication",
      "/passwordless/authentication/",
    ]
    const isVisible = !hiddenPagePaths.includes(location?.pathname)
    setIsVisible(isVisible)
  }, [location])

  useEffect(() => {
    if (typeof document !== "undefined" && isVisible) {
      const isTallNav = width > 1200 ? true : false
      document.body.style.paddingTop = isTallNav ? "80px" : "66px"
    }

    return () => {
      if (typeof document !== "undefined") {
        document.body.style.paddingTop = "0px"
      }
    }
  }, [isVisible, width])

  return (
    <>
      {isVisible && (
        <div className="header" role="banner">
          <AccountMenu siteTitle={siteTitle} />
          <BannerMessage />
          <FinalistBanner />
        </div>
      )}
    </>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
