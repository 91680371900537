export const allRequiredFields = {
  work: [
    {
      key: "title",
      label: "Title",
    },
    {
      key: "employer",
      label: "Employer",
    },
    {
      key: "location",
      label: "Location",
    },
    {
      key: "startDateMonth",
      label: "Start Month",
    },
    {
      key: "startDateYear",
      label: "Start Year",
    },
    {
      key: "endDateMonth",
      label: "End Month",
    },
    {
      key: "endDateYear",
      label: "End Year",
    },
  ],
  education: [
    {
      key: "school",
      label: "School",
    },
    {
      key: "location",
      label: "Location",
    },
    {
      key: "field",
      label: "Field of Study",
    },
    {
      key: "startDateMonth",
      label: "Start Month",
    },
    {
      key: "startDateYear",
      label: "Start Year",
    },
    {
      key: "endDateMonth",
      label: "End Month",
    },
    {
      key: "endDateYear",
      label: "End Year",
    },
  ],
  volunteer: [
    {
      key: "title",
      label: "Title",
    },
    {
      key: "employer",
      label: "Employer",
    },
    {
      key: "startDateMonth",
      label: "Start Month",
    },
    {
      key: "startDateYear",
      label: "Start Year",
    },
    {
      key: "endDateMonth",
      label: "End Month",
    },
    {
      key: "endDateYear",
      label: "End Year",
    },
    {
      key: "location",
      label: "Location",
    },
  ],
  leadership: [
    {
      key: "title",
      label: "Title",
    },
    {
      key: "affiliation",
      label: "Affiliation",
    },
    {
      key: "startDateMonth",
      label: "Start Month",
    },
    {
      key: "startDateYear",
      label: "Start Year",
    },
    {
      key: "endDateMonth",
      label: "End Month",
    },
    {
      key: "endDateYear",
      label: "End Year",
    },
    {
      key: "location",
      label: "Location",
    },
  ],
  award: [
    {
      key: "title",
      label: "Title",
    },
    {
      key: "issuer",
      label: "Issuer",
    },
    {
      key: "endDateMonth",
      label: "End Month",
    },
    {
      key: "endDateYear",
      label: "End Year",
    },
  ],
  life: [
    {
      key: "milestone",
      label: "Milestone",
    },
    {
      key: "description",
      label: "Description",
    },
    {
      key: "endDateMonth",
      label: "End Month",
    },
    {
      key: "endDateYear",
      label: "End Year",
    },
  ],
}

export const checkRequiredFields = (experience, type) => {
  let errors = []
  let requiredFields = allRequiredFields[type] || []

  // required fields for each type of experience
  requiredFields.forEach(({ key, label }) => {
    // users entry from form field
    const userEntry = experience[key]

    if (userEntry === undefined || userEntry?.length === 0) {
      errors.push(`${label}\n`)
    }

    // validate numerical entry
    if (key === "startDateYear" || key === "endDateYear") {
      // allow null entry for currently ongoing
      const isValidYear =
        userEntry === null ? true : /^(19|20)\d{2}$/.test(userEntry)

      // push year message only if nonexistant
      if (
        !isValidYear &&
        errors.indexOf(`Years must be formatted (YYYY) \n`) === -1
      ) {
        errors.push(`Years must be formatted (YYYY) \n`)
      }
    }

    // push error if end date is before start date
    if (key === "endDateYear" && userEntry !== null) {
      const startDate = new Date(experience.startDateYear,experience.startDateMonth)
      const endDate = new Date(experience.endDateYear, experience.endDateMonth)

      if (!!startDate && !!endDate) {
        Math.sign(endDate - startDate) === -1 &&
          errors.push(`End Date must be after Start Date`)

        Math.sign(endDate - Date.now()) === 1 &&
          errors.push(`End Date must be in the past`)

        Math.sign(startDate - Date.now()) === 1 &&
          errors.push(`Start Date must be in the past`)
      }
    }
  })

  if (errors.length > 0) {
    return errors
  } else {
    return false
  }
}
