import { Paragraph, TextRun } from "docx"

import { renderMonthName } from "../utils/utils.js"

export const renderLeadershipSection = leadershipExperiences => {
  return [
    new Paragraph({
      children: [
        new TextRun({
          text: "LEADERSHIP EXPERIENCE",
          bold: true,
          size: 21,
        }),
      ],
      spacing: {
        before: 200,
      },
      border: {
        bottom: {
          color: "#d3d3d3",
          space: 1,
          style: "single",
          size: 6,
        },
      },
    }),
    ...leadershipExperiences
      .map(experience => {
        const {
          title = "TITLE",
          location = "LOCATION",
          affiliation = "",
          highlights = [],
          startDateMonth = "",
          startDateYear = "",
          endDateMonth = "",
          endDateYear = "",
        } = experience

        const formattedDate = `${renderMonthName(
          startDateMonth
        )} ${startDateYear} - ${
          endDateYear
            ? `${renderMonthName(endDateMonth)} ${endDateYear} `
            : "current"
        }`

        return [
          new Paragraph({
            spacing: {
              before: 50,
            },
            children: [
              new TextRun({
                text: title ? `${title}` : "",
                bold: true,
                size: 21,
              }),
            ],
          }),
          new Paragraph({
            children: [
              new TextRun({
                text: `${affiliation} | ${location} | ${formattedDate ? formattedDate : 'TIME'}`,
              }),
            ],
          }),
          ...highlights
            .filter(highlight => !!highlight)
            .map(
              highlight =>
                new Paragraph({
                  bullet: {
                    level: 0,
                  },
                  children: [
                    new TextRun({
                      text: highlight,
                      font: "cambria",
                      size: 20,
                    }),
                  ],
                })
            )
            .flat(),
        ]
      })
      .flat(),
  ]
}
