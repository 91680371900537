import React, { useState, useEffect, useContext } from "react"
import ResumeTimelines from "./ResumeTimelines.js"
import ExperienceTypeSelector from "./experience-type-selector-modal"
import "./resume.css"

/**
 * Base component for resume, includes editor and public version
 */
const Resume = ({
  allResumeExperiences,
  isEditorModalOpen,
  isSelectorModalOpen,
  isPublicFacing,
}) => {
  return (
    <>
      <section
        className="resume"
        style={{
          padding: allResumeExperiences.length == 0 && "0",
          borderTop: allResumeExperiences.length == 0 && "none",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            marginBottom: "2rem",
          }}
        ></div>

        <ResumeTimelines
          isPublicFacing={isPublicFacing}
          experiences={allResumeExperiences.map((data, index) => ({
            ...data,
            index: index,
          }))}
        />

        {!isPublicFacing && (
          <ExperienceTypeSelector
            isEditorModalOpen={isEditorModalOpen}
            isSelectorModalOpen={isSelectorModalOpen}
          />
        )}
      </section>
    </>
  )
}

export default Resume
