export const renderMonthName = (monthNum, locale = 'en-US') => {
  switch (monthNum) {
    case "01":
      return locale === 'en-US' ? "January" : "Enero"

    case "02":
      return locale === 'en-US' ? "February" : "Febrero"

    case "03":
      return locale === 'en-US' ? "March" : "Marzo"

    case "04":
      return locale === 'en-US' ? "April" : "Abril"

    case "05":
      return locale === 'en-US' ? "May" : "Puede"

    case "06":
      return locale === 'en-US' ? "June" : "Junio"

    case "07":
      return locale === 'en-US' ? "July" : "Julio"

    case "08":
      return locale === 'en-US' ? "August" : "Agosto"

    case "09":
      return locale === 'en-US' ? "September" : "Septiembre"

    case "10":
      return locale === 'en-US' ? "October" : "Octubre"

    case "11":
      return locale === 'en-US' ? "November" : "Noviembre"

    case "12":
      return locale === 'en-US' ? "December" : "Diciembre"
    default:
      return ""
  }
}

const isPrecededBySameCompany = (precedingExperience, currentExperience) => {
  if (
    !precedingExperience ||
    !precedingExperience.employer ||
    !currentExperience.employer
  ) {
    return false
  }

  // normalize text entry and check for equality
  return (
    precedingExperience?.employer?.replace(/\s/g, "").toLowerCase() ===
    currentExperience.employer?.replace(/\s/g, "").toLowerCase()
  )
}

const isSucceededBySameCompany = (succeedeingExperience, currentExperience) => {
  if (
    !succeedeingExperience ||
    !succeedeingExperience.employer ||
    !currentExperience.employer
  ) {
    return false
  }

  // normalize text entry and check for equality
  return (
    succeedeingExperience?.employer?.replace(/\s/g, "").toLowerCase() ===
    currentExperience.employer?.replace(/\s/g, "").toLowerCase()
  )
}

export const filterExperiencesByType = (experiences, type) => {
  const filteredExperiences = experiences
    .filter(experience => experience.type === type)
    .sort((a, b) => {
      const aDate = new Date(+a.startDateYear, +a.startDateMonth)
      const bDate = new Date(+b.startDateYear, +b.startDateMonth)
      return bDate - aDate
    })

  if (type === "work") {
    return filteredExperiences.map((experience, index, array) => {

      const isPriorPosition = isPrecededBySameCompany(array[index - 1], experience)
      const isHeadPosition = !isPriorPosition && isSucceededBySameCompany(array[index + 1], experience)

      return { isPriorPosition: isPriorPosition, isHeadPosition: isHeadPosition, ...experience }
    }

    )
  } else {
    return filteredExperiences
  }
}
