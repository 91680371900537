/**
 * Organize a map of maps from Firestore.users.<userID>.profile.bookmarks
 * into groups by Cosmic object type.
 *
 * The resulting object contains arrays of strings, in a format that
 * can be used for an Algolia filter set.
 *
 * @example
 * {
 *   stories: [
 *     'objectID:5f9c7c4a069530000755bf79',
 *     'objectID:5f49158e2c548400070ea12c
 *   ]
 * }
 *
 * @param {object} bookmarks
 *
 * @return {null|object}
 */
export const organizeByType = (bookmarks) => {
  if (!bookmarks || typeof bookmarks !== 'object') {
    return null
  }

  const sortedBookmarks = {}

  Object.entries(bookmarks).forEach(item => {
    if (!sortedBookmarks[item[1].contentType]) {
      sortedBookmarks[item[1].contentType] = []
    }
    sortedBookmarks[item[1].contentType].push(`slug:${item[1].contentSlug}`)
  })

  return sortedBookmarks
}

/**
 * Generate valid Algolia filter list from saved items array.
 *
 * @param {object} organizedBookmarks - results of organizeByType
 * @param {string} type
 * @returns {null|string}
 */
export const getFilters = (organizedBookmarks, type) => {
  if (organizedBookmarks && organizedBookmarks.hasOwnProperty(type)) {
    return organizedBookmarks[type].join(' OR ')
  } else {
    return null
  }
}
