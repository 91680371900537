/**
 * Output array from Firestore.assessments.<userID>.resultProfile map
 *
 * Can also be used for career personality profiles
 *
 * The resulting object contains an array of numbers, in a format that
 * can be used for chart generation and correlational calculations
 *
 * @example
 * {
 *   resultProfile: {
 *      personality_type_artistic: 3.25,
        personality_type_conventional: 2,
        personality_type_enterprising: 3.5,
        personality_type_investigative: 4,
        personality_type_realistic: 4.75,
        personality_type_social: 2
 *   }
 * }
 *
 * outputs to [3.25, 2, 3.5, 2, 3.5, 4, 4.75, 2]
 *
 * @param {object} profile
 *
 * @return {null|array}
 */
export const arrayFromPersonalityType = profile => {
  // order of personality types, must be kept consistent or else correlation results will be wrong
  const typeOrder = [
    "personality_type_realistic",
    "personality_type_investigative",
    "personality_type_artistic",
    "personality_type_social",
    "personality_type_enterprising",
    "personality_type_conventional",
  ]

  if (!profile || typeof profile !== "object") {
    console.error("could not build array from profile")
    return null
  }

  // get correctly sorted array based upon currentPersonalityProfile
  const userPersonalityArray = Object.keys(profile).sort(
    (a, b) => typeOrder.indexOf(a) - typeOrder.indexOf(b)
  )

  return userPersonalityArray.map(key => profile[key])
}

export const getRIASECCode = profile => {
  // order of personality types, must be kept consistent or else correlation results will be wrong
  const typeOrder = [
    "personality_type_realistic",
    "personality_type_investigative",
    "personality_type_artistic",
    "personality_type_social",
    "personality_type_enterprising",
    "personality_type_conventional",
  ]

  if (!profile || typeof profile !== "object") {
    console.error("could not build array from profile")
    return null
  }

  const userPersonalityArray = Object.entries(profile).sort(
    (a, b) => b[1] - a[1]
  )

  const topTraits = userPersonalityArray
    .filter((personalityArr, index, arr) => {
      if (personalityArr[1] >= arr[2][1]) {
        return true
      }
    })
    .map(fullType => fullType[0].split("_")[2])

  const code = topTraits.map(trait => trait.charAt(0).toUpperCase()).join("")

  return {
    code: code,
    categories: topTraits,
  }
}

/**
 * Converts correlation coef to strength categories for users personal match
 *
 * outputs to "excellent" || "good" || "fair" || "none"
 *
 * output is used in labels as well as appended to CSS classes for variable styling
 *
 * @param {matchStrength} num between -1 and 1
 *
 * @return {string}
 */
export const calcMatchStrength = (matchStrength) => {
  if (matchStrength > 0.729) {
    return "Excellent"
  } else if (matchStrength > 0.608) {
    return "Good"
  } else if (matchStrength > 0) {
    return "Fair"
  } else {
    return "none"
  }
}

export const orderPersonalityStrength = profile => {
  if (!profile || typeof profile !== "object") {
    console.error("could not build array from profile")
    return null
  }

  const strengthSorted = Object.entries(profile).sort((a, b) => b[1] - a[1])

  return {
    labels: strengthSorted.map(category => category[0].split("_")[2]),
    values: strengthSorted.map(category => category[1]),
  }
}

export const makePosessive = name => {
  if (typeof name === "string" && name.length > 0) {
    return name.slice(-1) === "s" ? `${name}'` : `${name}'s`
  } else {
    return ""
  }
}

// removes careers that are absent on cosmic
export const removeMissingCareers = (matches, allCareers) => {
  return matches.filter(career => {
    if (allCareers.some(allCareer => allCareer.slug === career.slug)) {
      return true
    }
  })
}

// returns top 10 career matches that currently exist in cosmic that are set to display in results page
export const getTopTenMatches = (matches, allCareers) => {

  const avaliableCareers = matches.filter(career => {
    if (allCareers.some(allCareer => allCareer.slug === career.slug)) {
      return true
    }
  })

  const displayConfirmedCareers = avaliableCareers
    .filter(career => {
      let cosmicCareer = allCareers.find(
        allCareer => allCareer.slug === career.slug
      )

      if (cosmicCareer && cosmicCareer.career_display_top_match !== false) {
        return true
      } else {
        return false
      }
    })
    .slice(0, 10)

  return displayConfirmedCareers
}
