import React, { useContext } from "react"
import { ExperienceEditorContext } from "./ExperienceEditorContext"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTimes } from "@fortawesome/free-solid-svg-icons"

const ExperienceBulletInput = ({ index, placeholder }) => {
  const { experience = {}, dispatchHighlightAction } = useContext(
    ExperienceEditorContext
  )

  const updateData = event => {
    const value = event.target.value
    dispatchHighlightAction("UPDATE_INPUT_VALUE", value, index)
  }

  const removeInput = event => {
    event.preventDefault()
    dispatchHighlightAction("DELETE_INPUT_FIELD", null, index)
  }

  return (
    <>
      <div className="form-element" style={{ flexFlow: "unset", marginBottom: '1rem' }}>
        <textarea
          type="text"
          value={experience?.highlights[index] ?? ""}
          placeholder={placeholder ?? ''}
          onChange={updateData}
          rows={1}
        />
        <button style={{ marginLeft: "1rem" }} onClick={removeInput} style={{backgroundColor: 'white', paddingRight: '0px'}}>
        <FontAwesomeIcon icon={faTimes} size="md" color="#363636" />
        </button>
      </div>
    </>
  )
}

export default ExperienceBulletInput
