/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import { FirebaseContext, useAuth } from "./Firebase"

import Header from "./header"
import Footer from "./footer"
import LevelsModal from "./levels/LevelsModal.js"
import "./variables.css"
import "./layout.css"
import "./component.css"
import LanguageSwitcher from "./language-switcher/LanguageSwitcher"
import SimpleLocalize from "./SimpleLocalize"
import { walkThroughSteps } from "./get-started/utils/utils"

import { Router } from "@reach/router"
import Dashboard from "../components/dashboard/dashboard"
import englishMessages from "../../lang/en-US.json"
import spanishMessages from "../../lang/es-ES.json"
import PrivateRoute from "./Firebase/PrivateRoute"
import MyAccount from "./private/account"
import Subscription from "./private/subscription"
import CancelMembership from "./private/cancel"
import useClientSideLocale from "./hooks/useClientSideLocale.js"
import ProfilePage from "../pages/profile"

import { Toaster } from "sonner"

import MaintenancePage from "./maintenance/maintenance.js"
import FinalistBanner from "./promotional/banners/finalists.js"

// amplify doesnt allow blank ENV vars, convert empty string to null
const subdomain = process.env.GATSBY_ORGANIZATION_SUBDOMAIN === "MAIN" ? null : process.env.GATSBY_ORGANIZATION_SUBDOMAIN

// if localization is enabled, show language switcher and allow user to change language
const localizationEnabled = process.env.GATSBY_ENABLE_LOCALIZATION === "true"

// if maintenance is enabled, allow bypass for development on branch
const isMaintenanceBypassOn = process.env.GATSBY_MAINTENANCE_BYPASS === "true"

// Main layout component for entire site
const Layout = (props) => {
  const { user, firebase, loading, profile, updateAssessment, globalSiteConfig, localFirestore, notifier } = useAuth()
  const { children } = props

  const [signing, setSigning] = useState(false)
  const [pointTotals, setPointTotals] = useState(null)
  const [level, setLevel] = useState(null)
  const [walkThroughStepValues, setWalkThroughStepValues] = useState(walkThroughSteps)

  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  const locale = useClientSideLocale()

  let pageContext = locale === "es-ES" ? { language: "es-ES", messages: spanishMessages } : { language: "en-US", messages: englishMessages }

  return (
    <FirebaseContext.Provider
      value={{
        user,
        firebase,
        loading,
        profile,
        signing,
        setSigning,
        pointTotals,
        setPointTotals,
        walkThroughStepValues,
        setWalkThroughStepValues,
        updateAssessment,
        subdomain,
        level,
        setLevel,
        localFirestore,
        notifier,
      }}
    >
      {globalSiteConfig?.isMaintenanceOn && !isMaintenanceBypassOn ? (
        <MaintenancePage />
      ) : (
        <SimpleLocalize {...{ pageContext }}>
          <Header siteTitle={data.site.siteMetadata.title} />
          {!notifier.isDisabled && (
            <Toaster
              toastOptions={{
                style: {
                  boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
                },
                className: "toaster",
              }}
              pauseWhenPageIsHidden={true}
            />
          )}

          <LevelsModal />

          {/* public dashboards in both languages */}
          <Router basepath="/">
            <Dashboard path={"/dashboard"} pageContext={{ language: "en-US", messages: englishMessages }} />
            <Dashboard path={"/es-ES/dashboard"} pageContext={{ language: "es-ES", messages: spanishMessages }} />
          </Router>

          {/* member pages in both languages, private */}
          <Router basepath="/member">
            <PrivateRoute path="/account" component={MyAccount} pageContext={{ language: "en-US", messages: englishMessages }} />
            <PrivateRoute path="/subscription" component={Subscription} pageContext={{ language: "en-US", messages: englishMessages }} />
            <PrivateRoute path="/cancel" component={CancelMembership} pageContext={{ language: "en-US", messages: englishMessages }} />

            <PrivateRoute path="/es-ES/account" component={MyAccount} pageContext={{ language: "es-ES", messages: spanishMessages }} />
            <PrivateRoute path="/es-ES/subscription" component={Subscription} pageContext={{ language: "es-ES", messages: spanishMessages }} />
            <PrivateRoute path="/es-ES/cancel" component={CancelMembership} pageContext={{ language: "es-ES", messages: spanishMessages }} />
          </Router>

          {/* spanish public profile */}
          <Router basepath="/es-ES">
            <ProfilePage path="/profile/*" />
          </Router>

          {/* handles most content, pages built during build process */}
          <main role="contentinfo">{children}</main>

          {/* footer at bottom of all pages */}
          <Footer props={props} />
        </SimpleLocalize>
      )}
    </FirebaseContext.Provider>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
