import React, { useEffect } from "react"
import CardStories from "../cards/cards-stories"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faChevronRight,
  faChevronLeft,
} from "@fortawesome/free-solid-svg-icons"
import AlgoliaClientKeys from "./AlgoliaClientKeys"
import algoliasearch from "algoliasearch"
import {
  InstantSearch,
  Configure,
  Pagination,
  Hits,
  RefinementList,
} from "react-instantsearch-dom"
import "./algolia.css"
import "./algolia-explore.css"
import { FormattedMessage } from "react-intl"
import { useIntl } from "react-intl"

const searchClient = algoliasearch(
  AlgoliaClientKeys.appId,
  AlgoliaClientKeys.apiKey
)

const storyResult = ({ hit }) => {
  const {
    objectID,
    title,
    story_personal_photo,
    story_video_url,
    story_job_title,
    story_company,
    story_quote,
    rating_average,
    slug,
    type,
  } = hit

  return (
    <CardStories
      objectID={objectID}
      img={story_personal_photo}
      title={title}
      slug={slug}
      rating_average={rating_average}
      story_video_url={story_video_url}
      story_job_title={story_job_title}
      story_company={story_company}
      story_quote={story_quote}
      contentType={type}
    />
  )
}

const AlgoliaStoriesWeek = () => {
  const intl = useIntl()

  const localeFilter = `locale:${intl.locale}`
  // clear cache on mount and unmount
  useEffect(() => {
    searchClient.clearCache()
    return () => {
      searchClient.clearCache()
    }
  }, [])

  return (
    <InstantSearch
      indexName="stories_explore"
      refresh={true}
      searchClient={searchClient}
    >
      <div className={`stream stream-stories stream-stories-week`}>
        <div
          className="eyebrow component-eyebrow"
          style={{ marginBottom: "1.5rem" }}
        >
          <FormattedMessage
            id="algolia-stories-week-story-of-the-week"
            defaultMessage="Stories of the Week"
          />
        </div>
        <Configure hitsPerPage={3} filters={localeFilter} distinct />

        <Hits hitComponent={storyResult} />

        <RefinementList
          attribute="story_display_in_stories_week"
          defaultRefinement={["true"]}
        />

        <Pagination
          showFirst={false}
          showLast={false}
          translations={{
            previous: <FontAwesomeIcon icon={faChevronLeft} size="sm" />,
            next: <FontAwesomeIcon icon={faChevronRight} size="sm" />,
          }}
        />
      </div>
    </InstantSearch>
  )
}

export default AlgoliaStoriesWeek
