import { Paragraph, TextRun } from "docx"

import { renderMonthName } from "../utils/utils.js"

export const renderWorkSection = workExperiences => {
  return [
    new Paragraph({
      children: [
        new TextRun({
          text: "WORK EXPERIENCE",
          bold: true,
          size: 21,
        }),
      ],
      spacing: {
        before: 200,
      },
      border: {
        bottom: {
          color: "#d3d3d3",
          space: 1,
          style: "single",
          size: 6,
        },
      },
    }),
    ...workExperiences
      .map(experience => {
        const {
          title = "JOB TITLE",
          employer = "EMPLOYER NAME",
          location = "LOCATION",
          description = "",
          isPriorPosition = false,
          isHeadPosition = false,
          highlights = [],
          startDateMonth = "",
          startDateYear = "",
          endDateMonth = "",
          endDateYear = "",
        } = experience

        const formattedDate = `${renderMonthName(
          startDateMonth
        )} ${startDateYear} - ${
          endDateYear
            ? `${renderMonthName(endDateMonth)} ${endDateYear} `
            : "current"
        }`

        return [
          !isPriorPosition ?
          new Paragraph({
            children: [
              new TextRun({
                text: `${isHeadPosition ? employer : title }`,
                bold: true,
                size: 21,
              }),
            ],
            spacing: {
              before: 50,
            },
          }) : null,
          new Paragraph({
            children: [
              new TextRun({
                text: `${(isPriorPosition || isHeadPosition) ? title : employer} | ${location} | ${formattedDate ? formattedDate : 'TIME'}`,
                size: 20,
              }),
            ],
          }),
          description ?
          new Paragraph({
            children: [
              new TextRun({
                text: `${description}`,
                font: "cambria",
                size: 20,
              }),
            ],
          }) : null,
          ...highlights
            .filter(highlight => !!highlight)
            .map(
              highlight =>
                new Paragraph({
                  bullet: {
                    level: 0,
                  },
                  children: [
                    new TextRun({
                      text: highlight,
                      font: "cambria",
                      size: 20,
                    }),
                  ],
                })
            )
            .flat(),
        ].filter((element) => element !== null)
      })
      .flat(),
  ]
}
