import { useEffect, useState } from "react"
import getFirebaseInstance from "./firebase"
import useLocalFirestore from "./useLocalFirestore"
import useNotifications from "../notifications/useNotifications"
import useSiteMetrics from "../siteMetrics/useSiteMetrics"
import { hotjar } from "react-hotjar"

function useAuth() {
  const [user, setUser] = useState(null)
  const [firebase, setFirebase] = useState(null)
  const [loading, setLoading] = useState(true)
  const [profile, setProfile] = useState(null)
  const [globalSiteConfig, setGlobalSiteConfig] = useState(null)

  const localFirestore = useLocalFirestore(firebase)
  const notifier = useNotifications(firebase)
  const siteMetrics = useSiteMetrics(firebase)

  const updateAssessment = async () => {
    const assessmentRecord = await firebase?.getAssessmentRecord(user.uid)

    if (assessmentRecord) {
      setProfile({ ...profile, assessmentRecord })
    }
  }

  useEffect(() => {
    if(!!profile) {
      hotjar.initialize("3484205", "6")

      const { userId } = profile
      if ( userId ) {
        hotjar.identify(userId)
      }
    }
  }, [profile])

  useEffect(() => {
    let unsubscribe
    let profileUnsubscribe
    let globalSiteConfigUnsubscribe
    let userActivitiesUnsubscribe
    let userNotificationsUnsubscribe
    let userPointTotalUnsubscribe

    const firebaseInstance = getFirebaseInstance()
    setFirebase(firebaseInstance)

    unsubscribe = firebaseInstance.auth.onAuthStateChanged(userResult => {
      if (userResult) {
        firebaseInstance.loginCount(userResult.uid)
        profileUnsubscribe = firebaseInstance.getUserProfile({
          userId: userResult.uid,
          onSnapshot: async r => {
            const userMembership = await firebaseInstance.getUserMembership(
              userResult.uid
            )
            const assessmentRecord = await firebaseInstance.getAssessmentRecord(
              userResult.uid
            )

            setUser({
              ...userResult,
              email: userResult._delegate.email,
              providerId: userResult._delegate.providerId,
              membership: userMembership,
            })

            setProfile(r.empty ? null : { ...r.data(), assessmentRecord })

            setLoading(false)
          },
        })

        globalSiteConfigUnsubscribe = firebaseInstance.getGlobalSiteConfig({
          onSnapshot: async r => {
            const globalSiteConfig = r.data()
            setGlobalSiteConfig(globalSiteConfig)
          },
        })

        userActivitiesUnsubscribe = firebaseInstance.subscribeToUserActivities({
          onSnapshot: async r => {
            const object = r.data()
            localFirestore.setActivityRecord(object?.completedActivities || [])
          },
        })

        userNotificationsUnsubscribe = firebaseInstance.subscribeToUserNotifications({
          onSnapshot: async r => {
            const object = r.data()

            const notifications = object?.notifications?.filter((notification) => notification.isHidden !== true) || []
            
            const scheduledNotifications = object?.scheduledNotifications || []

            // Get the current date
            const currentDate = new Date();

            // Function to convert expiration data to JavaScript Date
            function getDateFromTimestamp(timestamp) {
                if (!timestamp) return null;
                const { seconds, nanoseconds } = timestamp;
                const milliseconds = (seconds * 1000) + (nanoseconds / 1000000);
                return new Date(milliseconds);
            }

            const sortedNotifications = notifications.sort((a, b) => { 
              return b.publicationDate - a.publicationDate
            }).filter((item) => {
              const expDate = getDateFromTimestamp(item.expirationDate);
              return !expDate || expDate > currentDate; // keep if no expiration date or expiration is in the future
          });            
            localFirestore.handleNewNotifications(sortedNotifications || [])
            localFirestore.setScheduledNotificationsRecord(scheduledNotifications || [])
            notifier.setHistoricalNotificationsRecord(object?.notifications || [])
          },
        })

        userPointTotalUnsubscribe = firebaseInstance.subscribeToUserPointTotal({
          onSnapshot: async r => {
            const object = r.data()
            const pointTotal = object?.pointTotal || null
            localFirestore.setPointTotalRecord(pointTotal)
          },
        })

      } else {
        // When we no longer have a logged in user
        // we cancel the snapshot listener for their profile.
        if (profileUnsubscribe) {
          profileUnsubscribe()
        }
        if (globalSiteConfigUnsubscribe) {
          globalSiteConfigUnsubscribe()
        }

        if (userActivitiesUnsubscribe) {
          userActivitiesUnsubscribe()
        }

        if (userNotificationsUnsubscribe) {
          userNotificationsUnsubscribe()
        }

        if (userPointTotalUnsubscribe) {
          userPointTotalUnsubscribe()
        }
        
        setUser(null)
        setLoading(false)
        setProfile(null)
      }
    })

    return () => {
      // on unmount, cancel firebase snapshot listeners
      if (unsubscribe) {
        unsubscribe()
      }

      if (profileUnsubscribe) {
        profileUnsubscribe()
      }
    }
  }, [])

  return {
    user,
    firebase,
    loading,
    profile,
    globalSiteConfig,
    updateAssessment,
    localFirestore, 
    notifier
  }
}

export default useAuth
