import React, { useEffect, useState } from "react"
import { useLocation } from "@reach/router"
import { NavDropdown, Navbar, Dropdown } from "react-bootstrap"
import { getCurrentPageLanguage } from "../../linkUtils"
import { defaultLanguage, languageNames } from "../../i18n"
import { changeLocale } from "../../link"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faGlobe } from "@fortawesome/pro-light-svg-icons"
import { useQueryParam, StringParam, JsonParam } from "use-query-params"
import "./language-switcher.css"

const LanguageSwitcher = (props) => {
  const location = useLocation()
  const [currentLanguage, setCurrentLanguage] = useState(
    languageNames[defaultLanguage]
  )
  const [cssClass, setCssClass] = useState(true)

  // URL search parameters for Algolia components
  const [queryFilters, setQueryFilters] = useQueryParam(
    "queryFilters",
    JsonParam
  )
  const [sortByPageQuery, setSortByPageQuery] = useQueryParam(
    "sortByPageQuery",
    StringParam
  )

  const loginSignUpPaths = [
    "/es-ES/login/individuals/",
    "/login/individuals/",
    "/signup/individuals/",
    "/es-ES/signup/individuals/",
    "/login/",
    "/es-ES/login/",
  ];

  useEffect(() => {
    if (location?.pathname) {
      if (loginSignUpPaths.includes(location.pathname)) {
        setCssClass(false)
      } else {
        setCssClass(true)
      }
    }
  }, [location]);

  useEffect(() => {
    const lang = getCurrentPageLanguage(location.pathname)
    setCurrentLanguage(languageNames[lang])
  }, [location])


  // Clear filters and change locale
  const handleLocaleChangeAndClearFilters = async (languageKey) => {
    setQueryFilters(undefined)
    setSortByPageQuery(undefined)
    changeLocale(languageKey)
  }

  return (
    <div style={{display: 'flex', alignItems: 'center', marginLeft: '.5rem'}}>
      <FontAwesomeIcon icon={faGlobe} color="white" />

        <NavDropdown
          alignRight
          title={currentLanguage}
          drop={props.top ? 'down' : 'up'}
          id={props.footer ? '' : `language-switcher`}
          onClick={() => {
            document.body.click()
          }}
        >
          
          {Object.keys(languageNames).map(languageKey => (
            <a
              key={languageKey}
              onClick={() => handleLocaleChangeAndClearFilters(languageKey)}
              className="dropdown-item menu-class"
            >
              {languageNames[languageKey]}
            </a>
          ))}
        </NavDropdown>

    </div>
  )
}

export default LanguageSwitcher
