import {
  Paragraph,
  TextRun,
} from "docx"

import { renderMonthName } from "../utils/utils.js"

export const renderEducationSection = educationExperiences => {
  if (educationExperiences.length === 0) {
    return []
  }

  return [
    new Paragraph({
      children: [
        new TextRun({
          text: "EDUCATION",
          bold: true,
          size: 21,
        }),
      ],
      border: {
        bottom: {
          color: "#d3d3d3",
          space: 1,
          style: "single",
          size: 6,
        },
      },
    }),
    ...educationExperiences
      .map(experience => {
        const {
          school = "SCHOOL",
          degree = "",
          location = "LOCATION",
          field = "",
          description = "",
          startDateMonth = "",
          startDateYear = "",
          endDateMonth = "",
          endDateYear = "",
          highlights = [],
        } = experience

        const formattedDate = `${renderMonthName(
          startDateMonth
        )} ${startDateYear} - ${
          endDateYear
            ? `${renderMonthName(endDateMonth)} ${endDateYear} `
            : "current"
        }`

        return [
          new Paragraph({
            spacing: {
              before: 50,
            },
            children: [
              new TextRun({
                text: `${school}`,
                bold: true,
                size: 21,
              }),
            ],
          }),
          new Paragraph({
            children: [
              new TextRun({
                text: `${degree} ${field} | ${location} | ${
                  formattedDate ? formattedDate : "DATE"
                }`,
              }),
            ],
          }),
          description
            ? new Paragraph({
                bullet: {
                  level: 0,
                },
                children: [
                  new TextRun({
                    text: description,
                    font: "cambria",
                    size: 20,
                  }),
                ],
              })
            : null,

          ...highlights
            .filter(highlight => !!highlight)
            .map(
              highlight =>
                new Paragraph({
                  bullet: {
                    level: 0,
                  },
                  children: [
                    new TextRun({
                      text: highlight,
                      font: "cambria",
                      size: 20,
                    }),
                  ],
                })
            )
            .flat(),
        ].filter(element => element !== null)
      })
      .flat(),
  ]
}
