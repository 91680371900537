import { useState, useEffect, useRef } from "react"
import {
  makeGoalNotification,
  makeSkillNotification,
  makeInterestNotification,
  makeNewCareerPlanNotification,
  makeDailyQuestCompletionNotification,
  makeStoryReadingNotification,
  makeNewBookmarkNotification,
  makeActivityCompletionNotification,
  makeNewResumeExperienceNotification,
  makeStoryMotivationalNotification,
  makeApproachingLevelNotification,
  makePersonalInfoNotification,
  makeShortTimedMotivationalNotification,
  makeMediumTimedMotivationalNotification,
  makeWelcomeNotification,
  makeDailyQuestChallengeCompletionNotification,
  makeReturningUserNotification,
} from "./notificationTemplates"

// This is a custom hook that handles notifications, local state is used to keep track of dynamic values used in composing notifications
const useNotifications = (firebase) => {
  const [isInitialized, setIsInitialized] = useState(false)
  const [isDisabled, setIsDisabled] = useState(false)
  const [historicalNotificationsRecord, setHistoricalNotificationsRecord] = useState([])

  const [goalsCount, setGoalsCount] = useState(0)
  const [skillsCount, setSkillsCount] = useState(0)
  const [interestsCount, setInterestsCount] = useState(0)

  const goalClockRef = useRef(null)
  const skillClockRef = useRef(null)
  const interestClockRef = useRef(null)

  const [userSessionMinutes, setUserSessionMinutes] = useState(0)

  const [isShortTimedMotivationSent, setIsShortTimedMotivationSent] = useState(false)
  const [isMediumTimedMotivationSent, setIsMediumTimedMotivationSent] = useState(false)

  useEffect(() => {
    /**
     * Initialize so that we can check if the user is logged in before any actions are taken
     */
    const init = async () => {
      const userId = firebase?.auth?._delegate?.currentUser?.uid || null

      if (isInitialized) return
      if (!userId) return

      setIsInitialized(true)
    }

    init()
  }, [firebase?.auth?._delegate?.currentUser?.uid, isInitialized])

  const handleWelcomeNotification = async () => {
    const notificationPayload = makeWelcomeNotification()
    await firebase.createUserNotification(notificationPayload)
  }

  const handleReturningUserNotification = async () => {
    const isDateToday = (timestamp) => {
      const date = new Date(timestamp.seconds * 1000)

      const preciseTime = date.setMilliseconds(date.getMilliseconds() + timestamp.nanoseconds / 1_000_000)
      // Get today's start (midnight)
      const todayStart = new Date()
      todayStart.setHours(0, 0, 0, 0)

      const todayEnd = new Date()
      todayEnd.setHours(23, 59, 59, 999)

      const isSentToday = preciseTime >= todayStart.getTime() && preciseTime <= todayEnd.getTime()

      return isSentToday
    }

    const hasBeenSentToday = historicalNotificationsRecord
      .filter((notification) => notification.templateId === "I2")
      .some((notification) => isDateToday(notification.publicationDate))

    if (hasBeenSentToday) {
      return null
    }

    const notificationPayload = makeReturningUserNotification()
    await firebase.createUserNotification(notificationPayload)
  }

  const handleGoalSetting = async () => {
    setGoalsCount((prevCount) => prevCount + 1)
    const notificationPayload = makeGoalNotification(goalsCount + 1)
    await firebase.createUserNotification(notificationPayload)
    startTimer(goalClockRef, 15000, () => setGoalsCount(0))
  }

  const handleSkillSetting = async () => {
    setSkillsCount((prevCount) => prevCount + 1)
    const notificationPayload = makeSkillNotification(skillsCount + 1)
    await firebase.createUserNotification(notificationPayload)
    startTimer(skillClockRef, 15000, () => setSkillsCount(0))
  }

  const handleInterestSetting = async () => {
    setInterestsCount((prevCount) => prevCount + 1)
    const notificationPayload = makeInterestNotification(interestsCount + 1)
    await firebase.createUserNotification(notificationPayload)
    startTimer(interestClockRef, 15000, () => setInterestsCount(0))
  }

  const handleNewCareerPlan = async (planName) => {
    const notificationPayload = makeNewCareerPlanNotification(planName)
    await firebase.createUserNotification(notificationPayload)
  }

  const handleDailyQuestCompletion = async (points) => {
    const notificationPayload = makeDailyQuestCompletionNotification(points)
    await firebase.createUserNotification(notificationPayload)

    const past5DaysQuestCount = await firebase.getPast5DaysQuestCompletionCount()

    if (past5DaysQuestCount === 5) {
      const notificationPayload = makeDailyQuestChallengeCompletionNotification()
      await firebase.createUserNotification(notificationPayload)
    }
  }

  const handleStoryReading = async (storyTitle, storySlug) => {
    const notificationPayload = makeStoryReadingNotification(storyTitle, storySlug)
    const motivationalNotificationPayload = makeStoryMotivationalNotification()
    await firebase.createUserNotification(notificationPayload)
    await firebase.createUserNotification(motivationalNotificationPayload)
  }

  const handleNewResumeExperience = async () => {
    const notificationPayload = makeNewResumeExperienceNotification()
    await firebase.createUserNotification(notificationPayload)
  }

  const handleNewBookmark = async (contentType, contentTitle, contentSlug) => {
    const notificationPayload = makeNewBookmarkNotification(contentType, contentTitle, contentSlug)
    await firebase.createUserNotification(notificationPayload)
  }

  const handleActivityCompletion = async (activityTitle, activitySlug) => {
    const notificationPayload = makeActivityCompletionNotification(activityTitle, activitySlug)
    await firebase.createUserNotification(notificationPayload)
  }

  const handleApproachingLevel = async ({ name, message, points: [goalPoints], userPoints }) => {
    const distanceToGoal = Math.abs(goalPoints - userPoints)
    const notificationPayload = makeApproachingLevelNotification({ name, message, distanceToGoal })
    await firebase.createUserNotification(notificationPayload)
  }

  const handlePersonalInfoSetting = async () => {
    const notificationPayload = makePersonalInfoNotification()
    await firebase.createUserNotification(notificationPayload)
  }

  const handleShortTimedMotivation = async () => {
    if (isShortTimedMotivationSent) return
    setIsShortTimedMotivationSent(true)

    const timedShortMotivationIds = ["M5", "M6", "M7", "M8", "M9", "M15", "M16", "M17", "M19", "M20"]

    let deliveredMotivationIdsSet = new Set(
      historicalNotificationsRecord
        .map((notification) => notification.templateId)
        .filter((templateId) => timedShortMotivationIds.includes(templateId))
    )

    if (deliveredMotivationIdsSet.size >= timedShortMotivationIds.length) {
      // reset these notifications
      for (const notification of historicalNotificationsRecord.filter((notification) => timedShortMotivationIds.includes(notification.templateId))) {
        await firebase.deleteUserNotification({ notificationId: notification.notificationId })
      }
    }

    const notificationPayload = makeShortTimedMotivationalNotification(deliveredMotivationIdsSet)
    if (!notificationPayload) return

    await firebase.createUserNotification(notificationPayload)
  }

  const handleMediumTimedMotivation = async () => {
    if (isMediumTimedMotivationSent) return
    setIsMediumTimedMotivationSent(true)

    const timedMediumMotivationIds = ["M10", "M11", "M12", "M13", "M14"]

    const deliveredMotivationIdsSet = new Set(
      historicalNotificationsRecord
        .map((notification) => notification.templateId)
        .filter((templateId) => timedMediumMotivationIds.includes(templateId))
    )

    if (deliveredMotivationIdsSet.size >= timedMediumMotivationIds.length) {
      // reset these notifications
      for (const notification of historicalNotificationsRecord.filter((notification) => timedMediumMotivationIds.includes(notification.templateId))) {
        await firebase.deleteUserNotification({ notificationId: notification.notificationId })
      }
    }

    const notificationPayload = makeMediumTimedMotivationalNotification(deliveredMotivationIdsSet)
    if (!notificationPayload) return

    await firebase.createUserNotification(notificationPayload)
  }

  // Function to start the timer, this will reset the goals count after a certain amount of time
  const startTimer = (clockRef, expirationTime, resetCallback) => {
    // If a timer is already running, clear it
    if (clockRef.current) {
      clearTimeout(clockRef.current)
    }
    // Set a new timer that resets the state after
    clockRef.current = setTimeout(() => {
      resetCallback()
    }, expirationTime)
  }

  useEffect(() => {
    if (!isInitialized) return
    // Set an interval to increment the minutes state every minute
    const intervalId = setInterval(() => {
      // Check if the page is currently visible
      const isPageVisible = () => {
        if (typeof window === "undefined") {
          return false
        } else {
          return !document.hidden
        }
      }

      if (isPageVisible()) {
        setUserSessionMinutes((prevMinutes) => prevMinutes + 1)
      }
    }, 60000)

    return () => {
      // clean up timers
      if (goalClockRef.current) {
        clearTimeout(goalClockRef.current)
      }

      if (skillClockRef.current) {
        clearTimeout(skillClockRef.current)
      }

      if (interestClockRef.current) {
        clearTimeout(interestClockRef.current)
      }

      clearInterval(intervalId)
    }
  }, [isInitialized])

  useEffect(() => {
    if (userSessionMinutes === 3) {
      handleShortTimedMotivation()
    } else if (userSessionMinutes === 10) {
      handleMediumTimedMotivation()
    }
  }, [userSessionMinutes])

  return {
    isDisabled,
    setIsDisabled,
    handleGoalSetting,
    handleSkillSetting,
    handleInterestSetting,
    handleNewCareerPlan,
    handleDailyQuestCompletion,
    handleStoryReading,
    handleNewBookmark,
    handleActivityCompletion,
    handleNewResumeExperience,
    handleApproachingLevel,
    handlePersonalInfoSetting,
    setHistoricalNotificationsRecord,
    handleWelcomeNotification,
    handleReturningUserNotification,
  }
}

export default useNotifications
