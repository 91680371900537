import React, { useContext, useState } from "react"
import { FirebaseContext } from "../Firebase"
import { PortfolioContext } from "./PortfolioContext"
import { Dropdown, Button } from "react-bootstrap"
import ResumeTipsModal from "../resume/tips/tips-modal"
import { saveAs } from "file-saver"
import ExamplePDF from "../resume/example/sample-resume.pdf"
import Link from "../../link"
import useModal from "../hooks/useModal"
import PortfolioShareModal from "./portfolio-share-modal"
import { FormattedMessage } from "react-intl"

const PortfolioHeading = () => {
  const { profile } = useContext(FirebaseContext)
  const { handleModalChange, handlePdfExport, handleResumeExport } = useContext(
    PortfolioContext
  )
  const [isShowingPortfolioShareModal, togglePortfolioShareModal] = useModal()

  const saveResumeExample = () => {
    saveAs(ExamplePDF, "wingspans-sample-resume")
  }

  return (
    <div>
      <PortfolioShareModal
        show={isShowingPortfolioShareModal}
        togglePortfolioShareModal={togglePortfolioShareModal}
      />
      {profile && profile.firstName && (
        <div
          style={{
            display: "flex",
            alignItems: "flex-start",
            flexWrap: "wrap",
            gap: "1rem",
            marginTop: "7rem",
            marginBottom: "1rem",
          }}
        >
          <h2 style={{ display: "block", marginRight: "2rem" }}>
            {profile.firstName} {profile.lastName ?? ""}
          </h2>
          <button
            onClick={e => {
              e.target.blur()
              handleModalChange("SELECTION")
            }}
            className="printer-none button component-button component-button-ghost component-button-ghost-inactive component-button-small"
          >
            + <FormattedMessage
              id="portfolio-heading-add-experience"
              defaultMessage="Add experience"
            />
          </button>

          <Dropdown className="printer-none">
            <Dropdown.Toggle
              className="button component-button component-button-ghost component-button-ghost-inactive component-button-small custom-more"
              id="dropdown-basic"
            >
              <FormattedMessage
                id="portfolio-heading-more"
                defaultMessage="More"
              />
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item onClick={() => togglePortfolioShareModal()}>
                <FormattedMessage
                  id="portfolio-heading-profile-sharing"
                  defaultMessage="Profile Sharing"
                />
              </Dropdown.Item>
              <Dropdown.Item onClick={() => saveResumeExample()}>
                <FormattedMessage
                  id="portfolio-heading-example-resume"
                  defaultMessage="Example Resume"
                />
              </Dropdown.Item>
              <Dropdown.Item onClick={() => handleResumeExport()}>
                <FormattedMessage
                  id="portfolio-heading-export-resume-to-word"
                  defaultMessage="Export Resume to Word"
                />
              </Dropdown.Item>
              <Dropdown.Item onClick={() => handlePdfExport()}>
                <FormattedMessage
                  id="portfolio-heading-export-portfolio-to-PDF"
                  defaultMessage="Export Portfolio to PDF"
                />
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>

          <div
            className="portfolio-personal-location"
            style={{ marginLeft: "auto" }}
          >
            {profile && !!profile.city && !profile.state && (
              <>
                <span>{profile.city}</span>
              </>
            )}
            {profile && !profile.city && !!profile.state && (
              <>
                <span>{profile.state}</span>
              </>
            )}
            {profile && !!profile.city && !!profile.state && (
              <>
                <span>
                  {profile.city}, {profile.state}
                </span>
              </>
            )}
          </div>
        </div>
      )}{" "}
    </div>
  )
}

export default PortfolioHeading
