import React, { useState, useEffect, useContext } from "react"
import PropTypes from "prop-types"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faBookmark as fasBookmark } from "@fortawesome/free-solid-svg-icons"
import { faBookmark as farBookmark } from "@fortawesome/free-regular-svg-icons"
import { FirebaseContext } from "../Firebase"
import { notificationOptions } from "../dashboard/notifications/notificationOptions"
import { FormattedMessage, useIntl } from "react-intl"

import "./bookmark.css"


const Bookmark = props => {
  const { profile, firebase, notifier } = useContext(
    FirebaseContext
  )
  const { style, label, classContext, contentId, contentSlug, contentType, contentTitle = '', jobCategories, careerPathwaysFilter } = props
  const [bookmarked, setBookmarked] = useState(false)
  const [showComponent, setShowComponent] = useState(true)
  const { locale } = useIntl()

  useEffect(() => {
    // Gatekeeper in case we don't get the props we depend on
    if (!contentSlug || !contentType) {
      // todo remove this after updating all components
      console.info("Bookmarks hidden due to missing prop!")
      setShowComponent(false)
    }

    if (profile && contentSlug) {
      // Profile is handled with a snapshot subscriber, so we leverage
      // profile updates rather than using component state to track bookmarks.
      // This way, if a bookmark is updated in another tab, the change will trigger
      // useEffect to update this one immediately.
      if (!!profile.bookmarks && profile.bookmarks[contentSlug]) {
        setBookmarked(true)
      } else {
        setBookmarked(false)
      }
    }
  }, [profile, contentId, contentSlug, contentType])

  /**
   * Insert bookmark into the existing set.
   *
   * @param {string} contentId
   * @param {string} contentType
   * @param {string} contentSlug
   */
  const addBookmark = async (contentSlug, contentId, contentType, contentTitle, jobCategories, careerPathwaysFilter) => {
    let careerData = {
      jobCategoryInterestTitle: jobCategories?.title,
      jobCategoryInterestSlug: jobCategories?.slug,
      careerClusterInterests: careerPathwaysFilter,
    }

    let newData
    
      newData = {
        ...profile.bookmarks,
        [contentSlug]: {
          contentId,
          contentSlug,
          contentType,
          contentTitle,
          dateAdded: new Date(),
          ...(contentType === 'careers' ? careerData : {}), // Conditionally spread careerData
        },
      }

    persistData(newData, 'ADD')
    await notifier.handleNewBookmark(contentType, contentTitle, contentSlug)
  }

  /**
   * Remove a bookmark entry from the existing set.
   *
   * @param {string} contentSlug
   * @returns {null}
   */
  const removeBookmark = contentSlug => {
    const items = profile.bookmarks 
    if (!items || !items[contentSlug]) {
      return null
    }

    delete items[contentSlug]
    persistData(items, 'REMOVE')
  }


  const persistData = (newData, addingOrRemovingFlag) => {

    firebase.updateProfile({ data: { ['bookmarks']: newData } }).catch(() => {
      alert(
        <FormattedMessage id="bookmark-error-alert-message" defaultMessage="There was a problem bookmarking this content. Please try again later." />
      )
    })
  }


  /**
   * Handle keypress events for the Bookmark toggle.
   * @param {SyntheticEvent} e
   */
  const handleKeyPress = e => {
    if (e.key === " " || e.key === "Enter" || e.key === "Spacebar") {
      e.preventDefault()
      toggleBookmark()
    }
  }

  /**
   * Handle click/touch events for the Bookmark toggle.
   * @param {SyntheticEvent} e
   */
  const handleClick = e => {
    e.preventDefault()
    toggleBookmark()
  }

  /**
   * Handle bookmark changes for both clicks and key presses.
   */
  const toggleBookmark = () => {
    if (bookmarked) {
      removeBookmark(contentSlug)
    } else {
      addBookmark(contentSlug, contentId, contentType, contentTitle, jobCategories, careerPathwaysFilter)
    }
    setBookmarked(!bookmarked)
  }

  // To prevent bad data, don't show the feature if we don't have the content information.
  // todo: remove along with state logic when components updated
  if (!showComponent) {
    return <></>
  }

  return (
    <div
      style={style}
      className={`bookmark component-bookmark component-bookmark-${classContext}`}
      onClick={handleClick}
      onKeyDown={handleKeyPress}
      role="button"
      tabIndex="0"
    > {!!label && <span>{label}</span>}
      {!!bookmarked && <FontAwesomeIcon icon={fasBookmark} />}
      {!bookmarked && <FontAwesomeIcon icon={farBookmark} />}
    </div>
  )
}

Bookmark.propTypes = {
  contentSlug: PropTypes.string.isRequired,
  contentId: PropTypes.string.isRequired,
  contentType: PropTypes.string.isRequired,
}

export default Bookmark
