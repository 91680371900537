import {
  AlignmentType,
  Document,
  Packer,
  Paragraph,
  Header,
  TextRun,
} from "docx"
import { saveAs } from "file-saver"
import { renderWorkSection } from "./renderWorkSection.js"
import { renderVolunteerSection } from "./renderVolunteerSection.js"
import { renderEducationSection } from "./renderEducationSection.js"
import { renderAwardSection } from "./renderAwardSection.js"
import { renderLifeSection } from "./renderLifeSection.js"
import { renderSkillsSection } from "./renderSkillsSection.js"
import { renderInterestsSection } from "./renderInterestsSection.js"
import { renderLeadershipSection } from "./renderLeadershipSection.js"

import { filterExperiencesByType } from "../utils/utils.js"

const GenerateResumeDOCX = ({name, email, experiences, skills, interests, city, state}) => {
    const work = filterExperiencesByType(experiences, "work")
    const education = filterExperiencesByType(experiences, "education")
    const volunteer = filterExperiencesByType(experiences, "volunteer")
    const awards = filterExperiencesByType(experiences, "award")
    const life = filterExperiencesByType(experiences, "life")
    const leadership = filterExperiencesByType(experiences, "leadership")

    const workExperiences = renderWorkSection(work)
    const volunteerExperiences = renderVolunteerSection(volunteer)
    const leadershipExperiences = renderLeadershipSection(leadership)
    const educationExperiences = renderEducationSection(education)
    const awardExperiences = renderAwardSection(awards)
    const lifeExperiences = renderLifeSection(life)
    const renderedSkills = renderSkillsSection(skills)
    const renderedInterests = renderInterestsSection(interests)

    const doc = new Document({
      styles: {
        paragraphStyles: [
          {
            name: "Normal",
            id: "work-history",
            run: {
              font: "cambria",
              size: 20,
            },
            paragraph: {
              font: "cambria",
              size: 20,
            },
          },
        ],
      },
      sections: [
        {
          headers: {
            default: new Header({
              children: [
                new Paragraph({
                  children: [
                    new TextRun({
                      text: `${name ?? "NAME"}`,
                      font: "cambria",
                      size: 32,
                      bold: true,
                    }),
                  ],
                  alignment: AlignmentType.CENTER,
                  bold: true,
                }),
                new Paragraph({
                  text: `${city ? city : 'CITY'}, ${state ? state : 'STATE'} ZIP - PHONE - ${
                    email ?? "EMAIL"
                  }`,
                  alignment: AlignmentType.CENTER,
                  // spacing: {
                  //   after: 200,
                  // },
                }),
              ],
            }),
          },
          properties: {
            page: {
                margin: {
                    top: 0,
                    right: 720,
                    bottom: 300,
                    left: 720,
                },
            },
        },
          children: [
            ...educationExperiences,
            ...workExperiences,
            ...volunteerExperiences,
            ...leadershipExperiences,
            ...awardExperiences,
            ...lifeExperiences,
            ...renderedSkills,
            ...renderedInterests,
          ],
        },
      ],
    })

    Packer.toBlob(doc).then(blob => {
      saveAs(blob, `${name.replace(/\s+/g, '-').toLowerCase()}-resume.docx`)
    })
}

export default GenerateResumeDOCX
