import React, { useContext, useEffect, useState } from "react"
import { graphql, useStaticQuery } from "gatsby"
import Link from "../../link"
import { FirebaseContext } from "../Firebase"
import CareerPlanView from "./career-plan-view"
import allowedTypes from "./AllowedTypes"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faEdit, faTimes, faSpinner } from "@fortawesome/free-solid-svg-icons"
import { Button, Confirm } from "semantic-ui-react"

import "semantic-ui-css/components/modal.min.css"
import "semantic-ui-css/components/dimmer.min.css"
import "./career-plans.css"
import { FormattedMessage, useIntl } from "react-intl"

const copyText = (
  <p>
    <FormattedMessage
      id="career-plan-para-1"
      defaultMessage="Make your future a reality with our career planning tool! Create a
      personalized timeline using our Guided or Unguided workflow. Choose from
      pre-populated career pathways or start from scratch and build your own.
      Don't be afraid to dream big and set 3-month, 1-year, and 10-year goals. 
      Get started now to unlock your full potential!"
    />
  </p>
)

/**
 * Component to load all career plans for a user.
 */
const CareerPlans = props => {
  /**
   * Milestone labeling data.
   *
   * @type {{}}
   */
  const milestoneLabels = {}
  const { formatMessage, locale } = useIntl()

  const dataCosmicMilestones = useStaticQuery(graphql`
    {
      allCosmicjsMilestones {
        edges {
          node {
            metadata {
              description
              milestone_id
              goal_label
              goal_detail_label
              goal_detail_label_plural
            }
            title
            locale
          }
        }
      }
    }
  `)

  dataCosmicMilestones.allCosmicjsMilestones.edges.filter((data) => data.node.locale === locale).forEach(({ node }) => {
    const milestoneId = node.metadata.milestone_id
    if (
      !node.title ||
      !allowedTypes.includes(milestoneId) ||
      !node.metadata.goal_label
    ) {
      return null
    }
    milestoneLabels[node.metadata.milestone_id] = {
      title: node.title,
      goalLabel: node.metadata.goal_label,
      goalDetailLabel: node.metadata.goal_detail_label,
      goalDetailLabelPlural: node.metadata.goal_detail_label_plural,
    }
  })

  const { firebase, loading, user } = useContext(FirebaseContext)
  const [loadingPlans, setLoadingPlans] = useState(true)
  const [plans, setPlans] = useState([])
  const [removingPlan, setRemovingPlan] = useState(null)
  const [planLoadError, setPlanLoadError] = useState(false)

  const [open, setOpen] = useState(null)
  const handleConfirm = () => setOpen(null)
  const handleCancel = () => setOpen(null)

  /**
   * Deletes a plan from the user's account.
   *
   * @param planId
   *   The plan ID to delete.
   * @param index
   *   The current index of the plan in the user's plan list.
   */
  const removePlan = (planId, index) => {
    if (removingPlan) {
      return
    }
    setRemovingPlan({ planId, index })
    firebase.deleteCareerPlan(planId).then(
      () => {
        plans.splice(index, 1)
        setPlans([...plans])
        setRemovingPlan(null)
      },
      () => {
        setRemovingPlan(null)
      }
    )
  }

  /**
   * Load's a users plans.
   */
  useEffect(() => {
    let isCancelled = false

    if (loading) {
      return
    }

    if (firebase) {
      try {
        const getPlans = async () => {
          const userPlans = await firebase.loadCareerPlans(user._delegate.uid)
          if (!isCancelled) {
            setPlans(userPlans)
            setLoadingPlans(false)
            setPlanLoadError(false)
          }
        }
        getPlans()
      } catch (e) {
        console.log("💣", e)
        if (!isCancelled) {
          setLoadingPlans(false)
          setPlanLoadError(true)
        }
      }
    }

    return () => {
      isCancelled = true
    }
  }, [firebase, loading])


  // If plans are loading do not finish rendering.
  if (loadingPlans) {
    return <div><FormattedMessage
      id="account-loading"
      defaultMessage="Loading ..." /></div>
  }

  /**
   * Renders an error message or empty plan message.
   *
   * @return {JSX.Element}
   */
  const emptyPlans = () => {
    if (planLoadError) {
      return (
        <div className="message message-error">
          <FormattedMessage
            id="career-plan-loading-error"
            defaultMessage="There was an error loading your plans, try refreshing the page."
          />
        </div>
      )
    }
    return (
      <>
        <h2><FormattedMessage
          id="career-plan-career-plans"
          defaultMessage="Career Plans"
        /></h2>

        <div className="new-plan-container">
          <h6><FormattedMessage
            id="career-plan-add-a-new-plan"
            defaultMessage="Add A New Plan"
          /></h6>
          <div className="career-plan-tab-header-options">
            <Link
              className="printer-none button component-button component-button-ghost component-button-default first component-button-small"
              to="/create-a-plan"
            >
              +{" "}<FormattedMessage
                id="career-plan-guided"
                defaultMessage="Guided"
              />
            </Link>

            <Link
              className="printer-none button component-button component-button-ghost component-button-default last component-button-small"
              to="/career-builder"
            >
              +{" "}<FormattedMessage
                id="career-plan-unguided"
                defaultMessage="Unguided"
              />
            </Link>
          </div>
        </div>
        {copyText}
      </>
    )
  }

  /**
   * Gets the plans render if there are any.
   *
   * @return {JSX.Element|[]}
   */
  const getPlanRender = () => {
    if (!Array.isArray(plans) || plans.length < 1) {
      return emptyPlans()
    }

    const careersPlanRender = []
    careersPlanRender.push(copyText)
    plans.forEach((careerPlan, index) => {
      careersPlanRender.push(
        <div
          className="career-plan-planned"
          key={`career-plan-${index}-${careerPlan.id}`}
        >
          <div className="career-plan-planned-item-header">
            <Link to={`/career-builder?plan=${careerPlan.id}`}>
              <span className="sr-only"><FormattedMessage
                id="career-plan-edit"
                defaultMessage="Edit"
              /></span>
              <FontAwesomeIcon icon={faEdit} size="lg" />
            </Link>
            <h3>{careerPlan.label}</h3>
            <Button
              className="button component-button component-button-text"
              disabled={!!removingPlan}
              onClick={e => setOpen(index)}
            >
              {removingPlan?.index !== index ? (
                <>
                  <span><FormattedMessage
                    id="career-plan-remove"
                    defaultMessage="Remove"
                  />&nbsp;</span>
                  <FontAwesomeIcon icon={faTimes} size="lg" />
                </>
              ) : (
                <>
                  <span><FormattedMessage
                    id="career-plan-removing..."
                    defaultMessage="Removing..."
                  />&nbsp;</span>
                  <FontAwesomeIcon icon={faSpinner} spin size="lg" />
                </>
              )}
            </Button>
            <Confirm
              open={open === index}
              onCancel={handleCancel}
              onConfirm={() => {
                handleConfirm()
                removePlan(careerPlan.id, index)
              }}
              style={{ overflowWrap: 'normal' }}
              size={"tiny"}
              confirmButton={formatMessage({ id: "career-plan-remove", defaultMessage: "Remove" })}
              content={<h5 style={{padding: '1rem 1rem 0'}}><FormattedMessage
                id="career-plan-removing-are-you-sure-text"
                defaultMessage="Are you sure you would like to remove this plan?"
              /> </h5>}
            />
          </div>
          <div className="career-plan-planned-item">
            <CareerPlanView
              milestoneLabels={milestoneLabels}
              plan={careerPlan}
            />
          </div>
        </div>
      )
    })

    return careersPlanRender
  }

  /**
   * Displays creation buttons, but an error message if user is at the plan limit.
   *
   * @return {JSX.Element}
   */
  const getAddNewPlanSection = () => {
    if (Array.isArray(plans) && plans.length < 10 && plans.length >= 1) {
      return (
        <>
          <h2><FormattedMessage
            id="career-plan-career-plans"
            defaultMessage="Career Plans"
          /></h2>

          <div className="new-plan-container">
            <h6><FormattedMessage
              id="career-plan-add-a-new-plan"
              defaultMessage="Add A New Plan"
            /></h6>
            <div className="career-plan-tab-header-options">
              <Link
                className="printer-none button component-button component-button-ghost component-button-default first component-button-small"
                to="/create-a-plan"
              >
                +{" "}<FormattedMessage
                  id="career-plan-guided"
                  defaultMessage="Guided"
                />
              </Link>

              <Link
                className="printer-none button component-button component-button-ghost component-button-default last component-button-small"
                to="/career-builder"
              >
                +{" "}<FormattedMessage
                  id="career-plan-unguided"
                  defaultMessage="Unguided"
                />
              </Link>
            </div>
          </div>
        </>
      )
    } else if (!Array.isArray(plans) || plans.length < 1) {
      return null
    } else {
      return (
        <div className="message message-warning">
          <FormattedMessage
            id="career-plan-para-2"
            defaultMessage="You have reached the career plan limit of 10 plans, and will be unable
                to add any new plans."
          />

        </div>
      )
    }
  }

  return (
    <section className="career-plan-tab">
      <div className="career-plan-tab-header">{getAddNewPlanSection()}</div>
      <div className="career-plan-tab-header career-plan-tab-header-planned paragraph">
        {getPlanRender()}
      </div>
    </section>
  )
}

export default CareerPlans
