import React, { useContext, useState, useEffect } from "react"
import Link, { navigate } from "../../link"
import "bootstrap/dist/css/bootstrap.min.css"
import { Dropdown } from "react-bootstrap"
import { faSearch as farSearch } from "@fortawesome/pro-regular-svg-icons"
import { FirebaseContext } from "../Firebase"
import Modal from "react-bootstrap/Modal"
import StyledFirebaseAuth from "react-firebaseui/StyledFirebaseAuth"
import AlgoliaGlobalSearch from "../algolia/algolia-global"
import PortfolioProfile from "../../../static/assets/images/portfolio-profile-default.jpg"
import { useLocation } from "@reach/router"
// import HeaderLogo from "../../../static/assets/images/wingspans-w-icon_white.svg"
import HeaderLogo from "../../../static/assets/images/logo2.png"

import HeaderLogoOnly from "../../../static/assets/images/wingspans-w-icon_white-mobile.svg"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faBars } from "@fortawesome/free-solid-svg-icons"
import OutsideClickHandler from "react-outside-click-handler"
import GetStartedFlyout from "../get-started/get-started"
import ProgressCircle from "../progress-circle/progress-circle"
import { calculateCompletionPercent } from "../get-started/utils/utils"
import LEVELS from "../levels/Levels.js"
import LevelsModal from "../levels/LevelsModal"
import NotificationsDropdown from "../dashboard/notifications/notifications-dropdown"
import { FormattedMessage, useIntl } from "react-intl"
import LanguageSwitcher from "../language-switcher/LanguageSwitcher.js"
import { sendStandardToast } from "../toasts/standard-toast"

const GetStartedProgressPercent = ({ progressPercent, onClick }) => {
  return (
    <button
      className="progress-percent-button"
      style={{
        background: "transparent",
      }}
      onClick={onClick}
    >
      <div className="progress-circle-container">
        <ProgressCircle progressPercentage={progressPercent} size="42px" circleColor="var(--color-74e4fb)" backgroundColor="var(--color-363636)" />
        <div className="progress-text">{progressPercent}%</div>
      </div>
    </button>
  )
}

const AccountMenu = (props) => {
  const location = useLocation()

  const {
    firebase,
    user,
    profile,
    notifier,
    localFirestore: { level, pointTotalRecord },
    walkThroughStepValues,
    setWalkThroughStepValues,
  } = useContext(FirebaseContext)

  const { locale } = useIntl()
  const [showModal, setShowModal] = useState(false)
  const [showSignIn, setShowSignIn] = useState(true)
  const [isFirebase, setIsFirebase] = useState(false)
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const [currentPage, setCurrentPage] = useState(null)
  // This is for the mobile compact display only
  const [showSearch, setShowSearch] = useState(false)
  const [showFlyout, setShowFlyout] = useState(false)
  const [showLevelModal, setShowLevelModal] = useState(false)
  const [getStartedProgress, setGetStartedProgress] = useState(-1)
  const [walkthoughStepsLoaded, setWalkthroughStepsLoaded] = useState(false)

  const focusInput = () => {
    const inputElement = document.querySelector('input[aria-controls="react-autowhatever-1"][class="react-autosuggest__input"]')

    if (inputElement) {
      inputElement.focus()
      return
    }
  }

  useEffect(() => {
    if (showSearch) {
      focusInput()
    }
  }, [showSearch])

  const handleOutsideSearchClick = (e) => {
    if (e.target.parentNode.classList?.contains("search-toggle-button") || e.target.parentNode.classList?.contains("search-toggle-icon")) {
      return
    }
    // handles outside click for notifications dropdown
    setShowSearch(false)
  }

  const handleMobileSearchClick = () => {
    !showSearch ? setShowSearch(true) : setShowSearch(false)
  }

  const handleClose = () => setShowModal(false)

  const getWalkthroughSteps = async () => {
    const res = await firebase.getWalkthroughSteps()
    setWalkthroughStepsLoaded(true)
    if (!res) return
    setWalkThroughStepValues({ ...walkThroughStepValues, ...res })
  }

  const hiddenPaths = [
    "/signup/promo/all/",
    "/signup/promo/all",
    "/signup/promo/microsoft/",
    "/signup/promo/microsoft",
    "/signup/promo/google/",
    "/signup/promo/google",
  ]

  useEffect(() => {
    // hides the sign in button on matching paths
    if (location?.pathname) {
      setCurrentPage(location.pathname.split("/")[locale === "en-US" ? 2 : 3])

      hiddenPaths.includes(location.pathname) ? setShowSignIn(false) : setShowSignIn(true)
    }
  }, [location])

  useEffect(() => {
    let isCancelled = false
    if (firebase && user && !isCancelled) {
      setIsFirebase(true)
    } else {
      setIsFirebase(false)
    }

    return () => {
      isCancelled = true
    }
  }, [firebase, user])

  useEffect(() => {
    let isCancelled = false
    if (isFirebase && !isCancelled) {
      getWalkthroughSteps()

      return () => {
        isCancelled = true
      }
    }
  }, [isFirebase])

  useEffect(() => {
    const percentComplete = calculateCompletionPercent(walkThroughStepValues)
    setGetStartedProgress(percentComplete)
  }, [walkThroughStepValues])

  // @see https://github.com/firebase/firebaseui-web
  // @see https://github.com/firebase/firebaseui-web-react
  // https://firebaseopensource.com/projects/firebase/firebaseui-web/
  function getUiConfig() {
    return {
      signInFlow: "popup",
      credentialHelper: "none",
      signInOptions: [
        firebase.app.auth.GoogleAuthProvider.PROVIDER_ID,
        firebase.app.auth.EmailAuthProvider.PROVIDER_ID,
        {
          provider: "microsoft.com",
          providerName: "Microsoft",
          buttonColor: "#2F2F2F",
          loginHintKey: "login_hint",
        },
      ],
      // signInSuccessUrl: '/dashboard',
      callbacks: {
        signInSuccessWithAuthResult: async () => {
          setShowModal(false)

          const profileResponse = await firebase.getProfile()
          const membershipResponse = await firebase.getUserMembership()
          const now = new Date()
          // When using a free-forever coupon, no expiration is set for the account, so account for both cases
          const membershipIsActive = now.getTime() < membershipResponse?.expiresDate * 1000 || !membershipResponse?.expiresDate

          if (profileResponse && membershipResponse && membershipResponse.active && membershipIsActive === true) {
            navigate("/dashboard")
            setIsFirebase(true)
          } else {
            await firebase.logout()
            setIsFirebase(false)

            sendStandardToast({
              heading: (
                <FormattedMessage
                  id="403-para-1-warning"
                  defaultMessage="Warning: you do not have access to the site with this email
        but we are still determined to help you find your way in life!"
                />
              ),
              message: (
                <>
                  <FormattedMessage id="403-to-get-access-please" defaultMessage="To get access, please" />{" "}
                  <a href="/contact">
                    <FormattedMessage id="registration-form-contact" defaultMessage="contact" />
                  </a>{" "}
                  <FormattedMessage id="registration-form-notification-3-sub-part" defaultMessage="our team with questions." />
                </>
              ),
            })

            navigate("/")
          }
        },
      },
    }
  }

  const handleLogoutClick = async (e) => {
    e.preventDefault()

    const now = new Date()

    if (isFirebase) {
      try {
        // These need to be chained so that the update profile finishes
        // before the user is logged out.
        await firebase
          .updateProfile({ data: { logoutDate: now } })
          .then(firebase.logout())
          .then(navigate("/"))

        setIsFirebase(false)
      } catch (e) {
        console.error(e)
        setIsFirebase(false)
      }
    }
  }

  const handleFlyoutOpen = () => {
    setShowFlyout(true)
  }

  const handleWalkThroughStepChange = async (stepKey) => {
    setWalkThroughStepValues({
      ...walkThroughStepValues,
      [stepKey]: !walkThroughStepValues[stepKey],
    })
    const res = await firebase.saveWalkthroughStep(stepKey, !walkThroughStepValues[stepKey])
    if (!res) {
      return
    }
  }

  const handleShowLevelModalHide = () => {
    setShowLevelModal(false)
  }

  useEffect(() => {
    if (!pointTotalRecord) return

    if (pointTotalRecord >= 80 && pointTotalRecord < 100) {
      notifier.handleApproachingLevel({ ...LEVELS[3], userPoints: pointTotalRecord })
    } else if (pointTotalRecord >= 280 && pointTotalRecord < 300) {
      notifier.handleApproachingLevel({ ...LEVELS[4], userPoints: pointTotalRecord })
    } else if (pointTotalRecord >= 480 && pointTotalRecord < 500) {
      notifier.handleApproachingLevel({ ...LEVELS[5], userPoints: pointTotalRecord })
    } else if (pointTotalRecord >= 680 && pointTotalRecord < 700) {
      notifier.handleApproachingLevel({ ...LEVELS[6], userPoints: pointTotalRecord })
    } else if (pointTotalRecord >= 980 && pointTotalRecord < 1000) {
      notifier.handleApproachingLevel({ ...LEVELS[7], userPoints: pointTotalRecord })
    }
  }, [pointTotalRecord])

  return (
    <>
      {!!isFirebase && !!user && !!user?.email && !!user?.membership?.active ? (
        // this needs to account for inactive users with auth
        <>
          <div className="inner-header">
            <div className="logo">
              <Link to={user?.membership?.active ? "/dashboard" : "/"}>
                <img className="logo-image" src={HeaderLogo} alt={props.siteTitle} />
                <img className="logo-image-small" src={HeaderLogoOnly} alt={props.siteTitle} />
              </Link>
            </div>

            <div className="search-wrapper">
              <div className={`search-container ${showSearch ? "show" : "hide"}`}>
                {" "}
                <OutsideClickHandler onOutsideClick={handleOutsideSearchClick}>
                  <div className="search search-default" style={{ position: "relative", zIndex: "100000000" }}>
                    <AlgoliaGlobalSearch />
                    <FontAwesomeIcon className="search-autocomplete-icon" icon={farSearch} />
                  </div>
                </OutsideClickHandler>
              </div>
            </div>

            <button className="search-toggle-button" onClick={handleMobileSearchClick}>
              <FontAwesomeIcon
                className="search-toggle-icon"
                style={{
                  color: "#74e4fb",
                }}
                icon={farSearch}
              />
            </button>

            <div>
              <LanguageSwitcher top={true} />
            </div>

            <button
              className="profile-points"
              onClick={() => {
                setShowLevelModal(true)
              }}
            >
              {level !== null && LEVELS[level]?.image ? (
                level === 0 ? (
                  <FontAwesomeIcon icon={LEVELS[level].image} style={{ marginRight: "0.5rem" }} className="profile-point-image" />
                ) : (
                  <img src={LEVELS[level].image} alt={LEVELS[level].name} className="profile-point-image" />
                )
              ) : null}
              <div style={{ color: level ? LEVELS[level].profileColor : "" }}>{pointTotalRecord}</div>
            </button>

            <LevelsModal userOpened={showLevelModal} handleShowLevelModalHide={handleShowLevelModalHide} />

            {walkthoughStepsLoaded && getStartedProgress >= 0 && getStartedProgress < 100 && (
              <>
                <div className="get-started get-started-desktop-only">
                  {getStartedProgress > 0 ? (
                    <GetStartedProgressPercent progressPercent={getStartedProgress} onClick={handleFlyoutOpen} />
                  ) : (
                    <button className="get-started-button button component-button component-button-login" onClick={handleFlyoutOpen}>
                      <FormattedMessage id="account-menu-get-started" defaultMessage="Get Started" />
                    </button>
                  )}
                </div>
                <div className="get-started get-started-mobile-only">
                  {getStartedProgress > 0 ? (
                    <GetStartedProgressPercent progressPercent={getStartedProgress} onClick={handleFlyoutOpen} />
                  ) : (
                    <button className="get-started-button button component-button component-button-login" onClick={handleFlyoutOpen}>
                      <FormattedMessage id="account-menu-get-started" defaultMessage="Get Started" />
                    </button>
                  )}
                </div>
              </>
            )}

            <NotificationsDropdown profile={profile} portfolioProfile={PortfolioProfile} />

            <div className="profile-container">
              <div className="profile-header">
                <Dropdown alignRight>
                  <Dropdown.Toggle variant="success" id="dropdown-basic">
                    <div>
                      {!!profile && !!profile.profileImageUrl ? (
                        <img
                          className="profile-image"
                          style={{
                            maxWidth: "100%",
                            objectFit: "cover",
                            objectPosition: "center top",
                          }}
                          src={profile.profileImageUrl}
                          alt="user profile"
                        />
                      ) : (
                        <img className="profile-image" alt="user profile" src={PortfolioProfile} />
                      )}
                    </div>
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Dropdown.Item>
                      <Link className="dropdown-item" to="/dashboard">
                        <FormattedMessage id="account-menu-my-dashboard" defaultMessage="My Dashboard" />
                      </Link>
                    </Dropdown.Item>
                    <Dropdown.Item>
                      <Link className="dropdown-item" to="/member/account">
                        <FormattedMessage id="account-menu-my-account" defaultMessage="My Account" />
                      </Link>{" "}
                    </Dropdown.Item>
                    <Dropdown.Item>
                      <Link className="dropdown-item" to="/" onClick={handleLogoutClick}>
                        <FormattedMessage id="account-menu-logout" defaultMessage="Logout" />
                      </Link>
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>
          </div>

          <GetStartedFlyout
            profile={profile}
            show={showFlyout}
            setShow={setShowFlyout}
            onStepChange={handleWalkThroughStepChange}
            stepsData={walkThroughStepValues}
          />
        </>
      ) : (
        <div className="inner-header" style={{ display: "flex", justifyContent: "space-between" }}>
          <div className="logo">
            <Link to={user?.membership?.active ? "/dashboard" : "/"}>
              <img className="logo-image" src={HeaderLogo} alt={props.siteTitle} />
            </Link>
          </div>

          <div className="links">
            <Link to={"/signup/educators"} className="desktop-only">
              <div className={currentPage === "educators" ? "selected" : ""}>
                <FormattedMessage id="account-menu-educators" defaultMessage="Educators" />
              </div>
            </Link>
            <Link to={"/signup/employers"} className="desktop-only">
              <div className={currentPage === "employers" ? "selected" : ""}>
                <FormattedMessage id="account-menu-employers" defaultMessage="Employers" />
              </div>
            </Link>
            <Link to={"/login"} style={{ height: "unset" }}>
              <button>
                <FormattedMessage id="account-menu-sign-in" defaultMessage="Sign In" />
              </button>
            </Link>

            <div className="menu-toggle mobile-only">
              <FontAwesomeIcon color={"white"} style={{ cursor: "pointer" }} icon={faBars} size="lg" onClick={() => setIsMenuOpen(!isMenuOpen)} />
              <OutsideClickHandler onOutsideClick={() => setIsMenuOpen(false)}>
                <div
                  className="mobile-dropdown"
                  style={{
                    display: isMenuOpen ? "flex" : "none",
                    height: isMenuOpen ? "fit-content" : "0px",
                  }}
                >
                  <Link to={"/signup/educators"}>
                    <div className={currentPage === "educators" ? "selected" : ""}>
                      <FormattedMessage id="account-menu-educators" defaultMessage="Educators" />
                    </div>
                  </Link>
                  <Link to={"/signup/employers"}>
                    <div className={currentPage === "employers" ? "selected" : ""}>
                      <FormattedMessage id="account-menu-employers" defaultMessage="Employers" />
                    </div>
                  </Link>
                </div>
              </OutsideClickHandler>
            </div>
          </div>

          <Modal show={showModal} onHide={handleClose} centered>
            <Modal.Header closeButton>
              <Modal.Title as={"p"}>
                <FormattedMessage id="account-menu-sign-in" defaultMessage="Sign In" />
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>{firebase && <StyledFirebaseAuth uiConfig={getUiConfig()} firebaseAuth={firebase.auth} />}</Modal.Body>
            <Modal.Footer>
              <p>
                <FormattedMessage id="account-menu-don't-have-an-account" defaultMessage="Don't have an account?" />{" "}
                <Link to="/signup/individuals" onClick={handleClose}>
                  <FormattedMessage id="account-menu-sign-up" defaultMessage="Sign up" />
                </Link>
              </p>
            </Modal.Footer>
          </Modal>
        </div>
      )}
    </>
  )
}

export default AccountMenu
