import React, { useContext } from "react"
import ResumeTimeline from "./ResumeTimeline.js"
import { filterExperiencesByType } from "./utils/utils.js"
import { FormattedMessage } from "react-intl"
import PortfolioSkills from "../../components/dashboard/portfolio-skills"
import PortfolioInterests from "../../components/dashboard/portfolio-interests"
import { PortfolioContext } from "../dashboard/PortfolioContext"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faSchool,
  faBriefcase,
  faUsers,
  faUser,
  faHandsHelping,
  faMedal,
  faStars,
  faCircleHeart,
} from "@fortawesome/pro-solid-svg-icons"


const ResumeTimelines = ({ experiences = [], isPublicFacing }) => {
  const portfolioContext = useContext(PortfolioContext)
  const { isEditorModalOpen } = portfolioContext || { isEditorModalOpen: false }

  return (
    <div className="resume-timelines">
      <div className="resume-timelines-column-one">
        <ResumeTimeline
          type="education"
          label={<FormattedMessage id="resume-timeline-education" defaultMessage="Education" />}
          filteredExperiences={filterExperiencesByType(
            experiences,
            "education"
          )}
          isPublicFacing={isPublicFacing}
          icon={
            <FontAwesomeIcon
              icon={faSchool}
              color={"rgb(245, 142, 53)"}
              size={"sm"}
            />
          }
        />

        <ResumeTimeline
          type="leadership"
          label={<FormattedMessage id="resume-timeline-leadership" defaultMessage="Leadership" />}
          filteredExperiences={filterExperiencesByType(
            experiences,
            "leadership"
          )}
          isPublicFacing={isPublicFacing}
          icon={
            <FontAwesomeIcon
              icon={faUsers}
              color={"rgb(204, 58, 93)"}
              size={"sm"}
            />
          }
        />

        <ResumeTimeline
          type="award"
          label={<FormattedMessage id="resume-timeline-honors-and-awards" defaultMessage="Honors and Awards" />}
          filteredExperiences={filterExperiencesByType(experiences, "award")}
          isPublicFacing={isPublicFacing}
          icon={
            <FontAwesomeIcon
              icon={faMedal}
              color={"rgb(103, 199, 68)"}
              size={"sm"}
            />
          }
        />
        <PortfolioSkills isModalOpen={isEditorModalOpen} isPublicFacing={isPublicFacing}/>
        <PortfolioInterests isModalOpen={isEditorModalOpen} isPublicFacing={isPublicFacing}/>
      </div>
      <div className="resume-timelines-column-two">
        <ResumeTimeline
          type="work"
          label={<FormattedMessage id="resume-timeline-work" defaultMessage="Work Experience" />}
          filteredExperiences={filterExperiencesByType(experiences, "work")}
          isPublicFacing={isPublicFacing}
          icon={
            <FontAwesomeIcon
              icon={faBriefcase}
              color={"rgb(61, 154, 210)"}
              size={"sm"}
            />
          }
        />

        <ResumeTimeline
          type="volunteer"
          label={<FormattedMessage id="resume-timeline-volunteer" defaultMessage="Volunteer" />}
          filteredExperiences={filterExperiencesByType(
            experiences,
            "volunteer"
          )}
          isPublicFacing={isPublicFacing}
          icon={
            <FontAwesomeIcon
              icon={faHandsHelping}
              color={"#54D1C1"}
              size={"sm"}
            />
          }
        />

        <ResumeTimeline
          type="life"
          label={<FormattedMessage id="resume-timeline-life" defaultMessage="Life" />}
          filteredExperiences={filterExperiencesByType(experiences, "life")}
          isPublicFacing={isPublicFacing}
          icon={<FontAwesomeIcon icon={faUser} color={"#FAAD3D"} size={"sm"} />}
        />
      </div>
    </div>
  )
}

export default ResumeTimelines
