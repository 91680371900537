import React, { useEffect, useState, useContext } from "react"
import { FirebaseContext } from "../components/Firebase"
import EyeBrow from "../components/eyebrow/eyebrow"
import AlgoliaToggleSavedSelect from "../components/algolia/public-profile/algolia-public-saved-select"
import "../components/dashboard/portfolio.css"
import CareerPlansPublic from "../components/careers/career-plans-public"
import PortfolioBanner from "../../static/assets/images/portfolio-banner-default.jpg"
import PortfolioProfile from "../../static/assets/images/portfolio-profile-default.jpg"
import LoadingSpinner from "../components/loading/LoadingSpinner.js"
import AssessmentPortfolio from "../components/assessment/assessment-porfolio"
import { faUser } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import Resume from "../components/resume/Resume"
import { FormattedMessage } from "react-intl"

const ProfilePage = props => {
  const profileId = props["*"]
  const { firebase } = useContext(FirebaseContext)
  const [profile, setProfile] = useState(null)
  const [loadingPlans, setLoadingPlans] = useState(true)

  const [error, setError] = useState(null)

  useEffect(() => {
    if (firebase && profileId) {
      // attempt to load requested profile
      try {
        const fetchData = async () => {
          const publicProfile = await firebase.loadPublicProfile(profileId)
          if (publicProfile) {
            setProfile(publicProfile)
          } else {
            setError(true)
          }
        }
        fetchData().catch(e => {
          setError(true)
        })
      } catch (e) {
        console.log("error!", e)
      }
    }
  }, [firebase, profileId])

  const isPublicSection = section =>
    profile?.profilePublicSections?.[section] !== false

  if (error) {
    return <>Bad request</>
  }

  if (!profile && isPublicSection("career_plans") && loadingPlans) {
    return (
      <>
        <LoadingSpinner />
      </>
    )
  }

  return (
    <>
      <section className="profile profile-public">
        <div className="inner inner-profile inner-profile-public" style={{ paddingTop: '0' }}>
          <section className="portfolio-hero">
            <div
              className="portfolio-hero-image"
              style={{
                backgroundImage: `url(${!!profile && !!profile.wallpaperImageUrl
                    ? profile.wallpaperImageUrl
                    : PortfolioBanner
                  })`,
                backgroundPosition: "center center",
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
              }}
            >
              <div
                style={{ position: "absolute", height: "100%", width: "100%" }}
              >
                <div className="portfolio-hero-profile-manage">
                  <div className="portfolio-hero-profile-image">
                    <img
                      style={{
                        maxWidth: "100%",
                        objectFit: "cover",
                        objectPosition: "center top",
                      }}
                      src={
                        !!profile && !!profile.profileImageUrl
                          ? profile.profileImageUrl
                          : PortfolioProfile
                      }
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
          </section>

          <div>
            <div
              style={{
                display: "flex",
                alignItems: "flex-start",
                justifyContent: 'space-between',
                gap: "1rem",
                marginTop: "7rem",
                marginBottom: "1rem",
              }}
            >
              <h2>
                {profile.firstName} {profile.lastName ?? ""}
              </h2>

              <div className="portfolio-personal-location">
                <strong>City, State </strong>

                {profile && !!profile.city && <>{profile.city}</>}

                {profile && !!profile.city && !!profile.state && <>,&nbsp;</>}

                {profile && !!profile.state && <>{profile.state}</>}
              </div>
            </div>
          </div>

          {isPublicSection("personal_information") && (
            <section className="portfolio-personal">
              {!!profile.profileQuote && (
                <div className="portfolio-personal-quote blockquote">
                  <p>{profile.profileQuote}</p>
                </div>
              )}

              <div className="portfolio-personal-header">
                <div style={{ display: "flex", alignItems: "flex-start" }}>
                  <h3><FormattedMessage
                    id="profile-introduction"
                    defaultMessage="Introduction"
                  /></h3>
                </div>
              </div>

              <div className="portfolio-personal-info">
                {!!profile.aboutMe && (
                  <div className="portfolio-personal-about">
                    <EyeBrow text={"A little about me"} />
                    <p>{profile.aboutMe}</p>
                  </div>
                )}

                {!!profile.myStrengths &&
                  profile.myStrengths.trim().length > 0 && (
                    <div className="portfolio-personal-strengths">
                      <EyeBrow text={"My Strengths"} />
                      <p>{profile.myStrengths}</p>
                    </div>
                  )}

                {!!profile.myWeaknesses &&
                  profile.myWeaknesses.trim().length > 0 && (
                    <div className="portfolio-personal-weaknesses">
                      <EyeBrow text={"My Challenges"} />
                      <p>{profile.myWeaknesses}</p>
                    </div>
                  )}
              </div>
            </section>
          )}

          {isPublicSection("resume") && (
            <>
              <Resume
                allResumeExperiences={profile?.experiences ?? []}
                isEditorModalOpen={false}
                isSelectorModalOpen={false}
                isPublicFacing={true}
              />
            </>
          )}

          {!!profileId && isPublicSection("assessment") && (
            <AssessmentPortfolio profile={profile} hasPublicToggle={false} />
          )}

          {!!profileId && isPublicSection("career_plans") && (
            <CareerPlansPublic
              loadingPlans={loadingPlans}
              setLoadingPlans={setLoadingPlans}
              userId={profileId}
            />
          )}

          {!!profile && profile.bookmarks && isPublicSection("bookmarks") && (
            <AlgoliaToggleSavedSelect heading="Saved" profile={profile} />
          )}
        </div>
      </section>
    </>
  )
}

export default ProfilePage
